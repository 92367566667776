@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.page-banner
{
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    /* margin-bottom: 30px; */
}


.price-list table.first th
{
    border-top: 1px solid #DEE2E6;
}
.price-list table td,th
{
    border-bottom: 1px solid #DEE2E6;
}
.price-list table tfoot td,th
{
    border-bottom: 0;
}
.price-list table th{
    font-size: 14px;
    word-wrap: break-word;
    padding: 10px 0
}

.price-list table td{
    padding: 10px;
}

svg.marker-icon 
{
    color: #F73E7B;
}

.page-price-list .page-banner
{
    background-image: url(./Assets/Images/Image/pricelist2_md.jpg);

}
.page-gallery .page-banner
{
    background-image: url(./Assets/Images/Image/hair_tools.jpg);

}
.page-contact-us .page-banner
{
    background-image: url(./Assets/Images/Image/hand_cover.jpg); 
}


.text-hima
{
    background: linear-gradient(
        270.97deg,
        #FFE580 -21.36%,
        #FF7571 -2.45%,
        #EA5DAD 26.84%,
        #C2A0FD 64.15%,
        #3BF0E4 108.29%,
        #B2F4B6 159.03% );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 767px) {
    .page-banner
    {
        height: 250px;
    }
    .page-price-list .page-banner
    {
        background-image: url(./Assets/Images/Image/pricelist2_xs.jpg);
    
    }

    .footer
    {
        row-gap: 1em;
    }
}

.quality-list
{
    list-style: disc !important;
    padding-left: 40px;
}

.gallery-container
{
    margin-bottom: 40px;
}

.gallery-item {
  overflow: hidden;
  background-color: #f3f4f6; /* Light gray background while loading */
}

.gallery-item img {
  transition: transform 0.3s ease-in-out;
}

.gallery-item:hover img {
  transform: scale(1.05);
}